import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import Layout, { Section } from "../layout"
import SEO from "../seo"
import HornImage from "../images/horn/Horn"
import HardwareImage from "../images/horn/Hardware"
import Columns, { Column } from "../Columns"
import Tabs from "../Tabs"
import Badges from "../Badges"
import Features from "../Features"
import ImageRequirement from "../ImageRequirement"
import InstallerBanner from "../InstallerBanner"
import FreeQuoteCTA from "../FreeQuoteCTA"
import MobileAppFeatures from "../MobileAppFeatures"

const powerImage = require("../../images/requirements/power.svg")
const wifiImage = require("../../images/requirements/wifi.svg")
const whistle = require("../../images/requirements/whistle.png")

export default function HornSellingPage() {
  const intl = useIntl()

  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "title" })} />

      <Section>
        <Columns desktopReversed>
          <Column>
            <HornImage />
          </Column>
          <Column
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h2>
              <FormattedMessage id="horn.title" />
            </h2>
            <p>
              <FormattedMessage id="horn.description" />
            </p>
          </Column>
        </Columns>
      </Section>

      <Section>
        <FreeQuoteCTA />
      </Section>

      <MobileAppFeatures />

      <Section>
        <h4 className="text-center">
          <FormattedMessage id="horn.upgradeYourSecurity" />
        </h4>

        <Badges
          messages={[
            intl.formatMessage({ id: "horn.upgradeFeatureApp" }),
            intl.formatMessage({ id: "horn.upgradeFeatureSecure" }),
            intl.formatMessage({ id: "horn.upgradeFeatureEasy" }),
          ]}
        />
      </Section>

      <Section>
        <h4 className="text-center">
          <FormattedMessage id="whyWhistle" />
        </h4>
        <h5 className="text-center">
          <FormattedMessage id="whyAnswer" />
        </h5>

        <Features
          maxWidthDesktop="50%"
          features={[
            intl.formatMessage({ id: "whyFeatureBest" }),
            intl.formatMessage({ id: "horn.hornFeatureAlwaysOnline" }),
            intl.formatMessage({ id: "whyFeatureTarget" }),
            intl.formatMessage({ id: "whyFeatureEasy" }),
            intl.formatMessage({ id: "whyFeatureEverything" }),
            intl.formatMessage({ id: "whyFeatureSounds" }),
          ]}
        />
      </Section>

      <Section>
        <Columns>
          <Column>
            <HardwareImage />
          </Column>
          <Column>
            <Tabs
              tabs={["horn.hardware", "horn.requirements"]}
              render={currentTab => {
                if (currentTab === "horn.hardware") {
                  return (
                    <>
                      <h5 style={{ marginTop: "1em" }}>
                        <FormattedMessage id="horn.hardwareKitTitle" />
                      </h5>
                      <p>
                        <FormattedMessage id="horn.hardwareKitDescription" />
                      </p>
                      <h5>
                        <FormattedMessage id="horn.hardwareSetupTitle" />
                      </h5>
                      <p>
                        <FormattedMessage id="horn.hardwareSetupDescription" />
                      </p>
                    </>
                  )
                }
                if (currentTab === "horn.requirements") {
                  return (
                    <div style={{ display: "flex" }}>
                      <ImageRequirement
                        image={powerImage}
                        text="horn.requirementsPlug"
                      />
                      <ImageRequirement
                        image={whistle}
                        text="horn.requirementsWhistle"
                      />
                    </div>
                  )
                }
              }}
            />
          </Column>
        </Columns>
      </Section>

      <Section>
        <FreeQuoteCTA />
      </Section>

      <Section>
        <InstallerBanner />
      </Section>
    </Layout>
  )
}
