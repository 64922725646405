import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

import { Section } from "./layout"
import Columns, { Column } from "./Columns"
import FeatureAlarmTypes from "./images/landing/FeatureAlarmTypes"
import FeatureAlertEn from "./images/landing/FeatureAlertEn"
import FeatureAlertEs from "./images/landing/FeatureAlertEs"
import FeatureCommunicate from "./images/landing/FeatureCommunicate"
import FeatureLog from "./images/landing/FeatureLog"

export default function MobileAppFeatures() {
  const intl = useIntl()

  return (
    <>
      <Section>
        <Columns desktopReversed>
          <Column>
            <FeatureAlarmTypes />
          </Column>
          <Column>
            <h4>
              <FormattedMessage id="landing.featureAlarmTypes" />
            </h4>
            <p style={{ whiteSpace: "pre-wrap" }}>
              <FormattedMessage id="landing.featureAlarmTypesText" />
            </p>
          </Column>
        </Columns>
      </Section>

      <Section>
        <Columns>
          <Column>
            {intl.locale === "es" ? <FeatureAlertEs /> : <FeatureAlertEn />}
          </Column>
          <Column>
            <h4>
              <FormattedMessage id="landing.featureAlert" />
            </h4>
            <p style={{ whiteSpace: "pre-wrap" }}>
              <FormattedMessage id="landing.featureAlertText" />
            </p>
          </Column>
        </Columns>
      </Section>

      <Section>
        <Columns desktopReversed>
          <Column>
            <FeatureCommunicate />
          </Column>
          <Column>
            <h4>
              <FormattedMessage id="landing.featureCommunicate" />
            </h4>
            <p style={{ whiteSpace: "pre-wrap" }}>
              <FormattedMessage id="landing.featureCommunicateText" />
            </p>
          </Column>
        </Columns>
      </Section>

      <Section>
        <Columns>
          <Column>
            <FeatureLog />
          </Column>
          <Column>
            <h4>
              <FormattedMessage id="landing.featureLog" />
            </h4>
            <p style={{ whiteSpace: "pre-wrap" }}>
              <FormattedMessage id="landing.featureLogText" />
            </p>
          </Column>
        </Columns>
      </Section>
    </>
  )
}
